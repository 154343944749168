import React from 'react';
import styled from 'styled-components';
import {StaticQuery, graphql} from "gatsby";
import Img from "gatsby-image";
import fonts from '../../../styles/fonts';
import {Container} from 'styled-bootstrap-grid';
import Slider from "react-slick/lib";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

const HomeHeroContainer = styled(Container)`
    height: 400px;
    width: 100%;
    position: relative;
    margin: auto;
    @media(min-width: 992px) {
      height: 647px;
    }
`;

const HeroImg = styled(Img)`
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
`;

const HomeHeroOverlay = styled(Img)`
    height: 100%;
    width: 100%;
    // object-fit: cover;
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    @media(min-width: 992px) {
      width: 550px;
    }
`;

const HomeHeroTextContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 45px;
    padding-bottom: 45px;
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media(min-width: 992px) {
      padding-left: 90px;
      padding-right: 90px;
      width: 40%;
      padding-top: 0px;
      padding-bottom: 0px;
    }
`;

const HomeHeroTitle = styled.div`
    color: white;
    ${fonts.MontserratBold};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
`;

const BorderLineDiv = styled.div`
    height: 4px;
    width: 77px;
    background: #ADE100;
    margin-top: 20px;
    margin-bottom: 25px;
    @media(min-width: 992px) {
        margin-top: 35px;
        margin-bottom: 40px;
    }
`;

const HomeHeroDescription = styled.div`
    color: white;
    ${fonts.CormorantGaramondItalic};
    font-size: 40px;
    line-height: 48px;
    max-width: 355px;
`;

const Slide = styled.div`
  width: 100%;
  position: relative;
  outline: 0;
  border: 0;
`;

const HomeHero = ({data, slides, isLoading}) => {

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    initialSlide: 0,
  };
  return (
    <Container>
      <Slider {...settings}>
        {slides.map((slide, index) =>
          <Slide key={index}>
            <HomeHeroContainer>
              <HeroImg fluid={slide.node.image.fluid}/>
              <HomeHeroTextContainer>
                <HomeHeroTitle>{slide.node.title}</HomeHeroTitle>
                <BorderLineDiv/>
                <HomeHeroDescription>{documentToReactComponents(slide.node.content.json)}</HomeHeroDescription>
              </HomeHeroTextContainer>
              {!isLoading && <HomeHeroOverlay fluid={data.heroOverlay.childImageSharp.fluid}/>}
            </HomeHeroContainer>
          </Slide>
        )}
      </Slider>
    </Container>
  )
};

const HomeHeroQuery = (props) => (
  <StaticQuery
    query={graphql`
        query {
          heroOverlay: file(
            relativePath: { eq: "home/hero-overlay.png" }
          ) {
            childImageSharp {
              fluid(quality: 70, maxWidth: 860) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
    render={data => <HomeHero data={data} slides={props.slides} isLoading={props.isLoading}/>}
  />
);

export default HomeHeroQuery;