import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import HomeFeature from './HomeFeature';
import Img from "gatsby-image";
import fonts from '../../../styles/fonts'
import colors from '../../../styles/colors';
import {Link} from "gatsby";

const HomeIntroductionBlockContainer = styled.div`
    width: 100%;
`;

const BeigeContainer = styled(Container)`
    background: #F9F9F9;
    width: 100%;
    padding: 30px;
    padding-top: 45px;
    padding-bottom: 0px;
    box-sizing: border-box;
    @media(min-width: 992px) {
        padding-left: 90px;
        padding-right: 90px;
        padding-top: 55px;
        padding-bottom: 55px;
    }
    @media (min-width: 768px) and (max-width: 992px) {
        padding-bottom: 45px;
    }
`;

const RowStyled = styled(Row)`
    height: 100%;
    max-width: 1400px;
    margin: auto;
`;

const ColStyled = styled(Col)`
    height: 100%;
`;

const AboutUsTextContainer = styled.div`
    height: 100%;
    box-sizing: border-box;
    padding-left: ${props => props.padding && '30px'};
    padding-right: ${props => props.padding && '30px'};
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media(min-width: 992px) {
        padding-left: ${props => props.padding && '90px'};
        padding-right: ${props => props.padding && '90px'};
    }
`;

const AboutUsBlockTitle = styled.div`
    color: #555555;
    ${fonts.MontserratBold};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
`;

const AboutUsText = styled.div`
    ${fonts.CormorantGaramondLight};
    font-size: 30px;
    line-height: 38px;
    color: ${colors.black};
    padding-bottom: 40px;
`;

const BorderLineDiv = styled.div`
    height: 4px;
    width: 117px;
    background: #ADE100;
    margin-top: 20px;
    margin-bottom: 25px;
    @media(min-width: 992px) {
        margin-top: 35px;
        margin-bottom: 40px;
    }
`;

const AboutUsDescription = styled.div`
    color: #555555;
   ${fonts.CormorantGaramondLight};
   font-size: 40px;
   line-height: 48px;
   max-width: 625px;
   padding-bottom: 40px;
   @media(min-width: 992px) {
        font-size: 50px;
        line-height: 58px;
    }
`;

const WhiteContainer = styled(Container)`
    background: white;
    width: 100%;
    padding-top: 45px;
    padding-bottom: 0px;
    box-sizing: border-box;
    @media(min-width: 768px) {
        padding-top: 55px;
        padding-bottom: 55px;
    }
`;

const LocationImageContainer = styled.div`
    width: 100%;
    height: 375px;
    position: relative;
    @media(min-width: 768px) {
        height: 700px;
    }
`;

const LocationImg = styled(Img)`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute !important;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const ImgOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: black;
    opacity: 0.2;
`;

const ImageTitle = styled.div`
    color: white;
    ${fonts.CormorantGaramondBold};
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 6px;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
`;


const AboutUsBlock = (props) => {
    const { ourWorldFeatures, locationImage, meetTheTeamImage, transportImage } = props;

    return (
        <HomeIntroductionBlockContainer>
            <BeigeContainer>
                <Container>
                    <RowStyled>
                        <AboutUsTextContainer>
                            <AboutUsBlockTitle>Our World</AboutUsBlockTitle>
                            <BorderLineDiv />
                            <AboutUsDescription>Looking after our world  for future generations to enjoy.</AboutUsDescription>
                        </AboutUsTextContainer>
                    </RowStyled>
                </Container>
                <Container>
                    <RowStyled>
                        {ourWorldFeatures.map((ourWorldFeature, index) =>
                            <ColStyled md={4} key={index}><HomeFeature icon={ourWorldFeature.icon} title={ourWorldFeature.title} link={ourWorldFeature.link} /></ColStyled>
                        )}
                    </RowStyled>
                </Container>
            </BeigeContainer>
            <WhiteContainer>
               <Container>
                    <RowStyled>
                        <ColStyled md={6}>
                            <AboutUsTextContainer padding>
                                <AboutUsBlockTitle>About Us</AboutUsBlockTitle>
                                <BorderLineDiv />
                                <AboutUsDescription>Antibes, the heart of the yachting industry, is our home.</AboutUsDescription>
                                <AboutUsText>All our team are yachting professionals working from this Mediterranean charter destination. This hub gives us easy, efficient access to local markets and luxury Mediterranean ports such as Saint Tropez, Cannes, Nice, Monaco, San Remo and Olbia.</AboutUsText>
                            </AboutUsTextContainer>
                        </ColStyled>
                        <ColStyled md={6}>
                            <LocationImageContainer>
                              <Link to={'/location'}>
                                <LocationImg fluid={locationImage} />
                                <ImageTitle>Location</ImageTitle>
                                <ImgOverlay />
                              </Link>
                            </LocationImageContainer>
                        </ColStyled>
                        <ColStyled md={6}>
                            <LocationImageContainer>
                              <Link to={'/team'}>
                                <LocationImg fluid={meetTheTeamImage} />
                                <ImageTitle>Meet the team</ImageTitle>
                                <ImgOverlay />
                              </Link>
                            </LocationImageContainer>
                        </ColStyled>
                        <ColStyled md={6}>
                            <LocationImageContainer>
                                <Link to={'/transport'}>
                                    <LocationImg fluid={transportImage} />
                                    <ImageTitle>Transport</ImageTitle>
                                    <ImgOverlay />
                                </Link>
                            </LocationImageContainer>
                        </ColStyled>
                    </RowStyled>
               </Container>
            </WhiteContainer>
        </HomeIntroductionBlockContainer>
    )
}

export default AboutUsBlock;