import React from 'react';
import styled from 'styled-components';
import {Container,  Row, Col } from 'styled-bootstrap-grid';
import HomeFeature from './HomeFeature';
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";
import fonts from '../../../styles/fonts'
import RegisterInterest from '../../core/components/RegisterInterest';

const HomeIntroductionBlockContainer = styled.div`
    width: 100%;
`;

const BeigeContainer = styled(Container)`
    background: #F9F9F9;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 45px;
    padding-bottom: 0px;
    box-sizing: border-box;
    @media(min-width: 992px) {
        padding-left: 90px;
        padding-right: 90px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    @media (min-width: 768px) and (max-width: 992px) {
        padding-bottom: 45px;
    }
`;

const RowStyled = styled(Row)`
    height: 100%;
    max-width: 1400px;
    margin: auto;
`;

const ColStyled = styled(Col)`
    height: 100%;
`;

const WhiteContainer = styled(Container)`
    background: white;
    width: 100%;
    height: 755px;
    box-sizing: border-box;
    position: relative;
    @media(min-width: 768px) {
        height: auto;
        overflow: hidden;
    }
`;

const OrderImageContainer = styled.div`
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 30%;
    width: 100%;
    z-index: 0;
    @media(min-width: 768px) {
        height: 100%;
        top: 0;
        left: auto;
        bottom: 0;
        width: 50%;
    }
`;

const OrderImg = styled(Img)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const HomeHeroTextContainer = styled.div`
    height: 100%;
    box-sizing: border-box;
    padding-top: 45px;
    padding-bottom: 45px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media(min-width: 992px) {
        padding-left: 90px;
        padding-right: 90px;
        padding-top: 80px;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
`;

const HomeHeroTitle = styled.div`
    color: #555555;
    ${fonts.MontserratBold};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
`;

const BorderLineDiv = styled.div`
    height: 4px;
    width: 117px;
    background: #ADE100;
    margin-top: 20px;
    margin-bottom: 25px;
    @media(min-width: 992px) {
        margin-top: 35px;
        margin-bottom: 40px;
    }
`;

const WhiteOverlay = styled(Img)`
    width: 100%;
    height: 30%;
    position: absolute !important;
    right: 0;
    left: 0;
    top: auto;
    bottom: 0;
    z-index: 1;
    opacity: 0.9;
    display: none;
    @media(min-width: 768px) {
        width: 605px;
        height: 100%;
        position: absolute !important;
        right: calc(50% - 450px);
        left: auto;
        top: 0;
        bottom: 0;
        opacity: 1;
        display: block;
    }
`;

const OrderDescription = styled.div`
    color: #555555;
   ${fonts.CormorantGaramondLight};
   font-size: 40px;
   line-height: 48px;
   max-width: 465px;
   padding-bottom: 30px;
   @media(min-width: 992px) {
        font-size: 50px;
        line-height: 58px;
   }
`;

const OrderStep = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const StepNumber = styled.div`
    color: #ADE100;
    ${fonts.CormorantInfantRegular};
    font-size: 35px;
    line-height: 55px;
    width: 40px;
    @media(min-width: 992px) {
        font-size: 45px;
        line-height: 69.46px;
        width: 49.93px;
    }
`;

const StepDescription = styled.div`
    color: #555555;
    ${fonts.MontserratBold};
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    @media(min-width: 992px) {
        font-size: 15px;
        line-height: 30px;
        letter-spacing: 3px;
    }
`;




const HomeIntroductionBlock = (props) => {
    const { introFeatures, data, orderSteps, registrationOptions } = props;

    return (
        <HomeIntroductionBlockContainer>
            <BeigeContainer>
                <Container>
                    <RowStyled>
                        {introFeatures.map((introFeature, index) =>
                            <ColStyled md={4} key={index}><HomeFeature icon={introFeature.icon} title={introFeature.title} features={introFeature.features} /></ColStyled>
                        )}
                    </RowStyled>
                </Container>
            </BeigeContainer>
            <WhiteContainer>
                <Container>
                    <RowStyled>
                        <ColStyled md={6}>
                            <HomeHeroTextContainer>
                                <HomeHeroTitle>How to order</HomeHeroTitle>
                                <BorderLineDiv />
                                <OrderDescription>Create an online order in three easy steps.</OrderDescription>
                                {orderSteps && orderSteps.map((step, index) =>
                                    <OrderStep key={index}>
                                        <StepNumber>{index + 1}</StepNumber>
                                        <StepDescription>- {step} -</StepDescription>
                                    </OrderStep>
                                )}
                            </HomeHeroTextContainer>
                        </ColStyled>
                    </RowStyled>
                </Container>
                <OrderImageContainer>
                    <OrderImg fluid={data.homeOrder.childImageSharp.fluid} />
                </OrderImageContainer>
                <WhiteOverlay fluid={data.orderOverlay.childImageSharp.fluid} />
            </WhiteContainer>
            <RegisterInterest registrationOptions={registrationOptions}/>
        </HomeIntroductionBlockContainer>
    )
}

const HomeIntroductionBlockQuery = (props) => (
    <StaticQuery
        query={graphql`
        query {
          homeOrder: file(
            relativePath: { eq: "home/order.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 860) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          orderOverlay: file(
            relativePath: { eq: "home/order-overlay.png" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 860) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
        render={data => <HomeIntroductionBlock {...props} data={data} />}
    />
);

export default HomeIntroductionBlockQuery;