import React, { Component } from "react";
import styled from 'styled-components';
import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import HomeHero from '../components/home/components/HomeHero';
import HomeIntroductionBlock from "../components/home/components/HomeIntroductionBlock";
import StarIcon from '../resources/img/icons/star.png';
import HeartIcon from '../resources/img/icons/heart.png';
import CloudIcon from '../resources/img/icons/cloud.png';
import RecycleIcon from '../resources/img/icons/recycle.png';
import BoxIcon from '../resources/img/icons/box.png';
import WaterDropsIcon from '../resources/img/icons/waterdrops.png';
import HomeImageBlock from "../components/home/components/HomeImageBlock";
import AboutUsBlock from "../components/home/components/AboutUsBlock";
import HomeNewsBlock from "../components/home/components/HomeNewsBlock";

const WhiteBackground = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin: auto;
`;

class IndexPage extends Component {
  state = {
    isLoading: true
  }

  componentDidMount () {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  }

  render() {
    const path = this.props.location.pathname;
    const introFeatures = [{ icon: StarIcon, title: 'Products Range', features: ['Complete organic & non organic lists.', 'The finest selection.', 'Expansive choice'] }, { icon: HeartIcon, title: 'Customer Care', features: ['24 / 7 customer support.', 'Transparent pricing.', 'Efficient ordering & delivery.'] }, { icon: CloudIcon, title: 'Technology', features: ['Online shopping lists.', 'High tech support centre.', 'Logistics & delivery tracking.'] }];
    const orderSteps = ['Create an account', 'Create a shopping list', 'Confirm your order'];
    const registrationOptions = [{ region: 'Worldwide', description: 'Featuring 500+ fresh and pantry products, delivered by freight to land or shore worldwide.', link: '' }, { region: 'Mediterranean', description: 'Featuring 500+ fresh and pantry products, delivered by road to Mediterranean regions.', link: '' }];
    const ourWorldFeatures = [{ icon: RecycleIcon, title: 'Sustainable products', link: '/sustainable-produce' }, { icon: BoxIcon, title: 'Packaging', link: '/packaging' }, { icon: WaterDropsIcon, title: 'Water', link: '/water' }];
    const locationImage = get(this, 'props.data.location.childImageSharp.fluid');
    const meetTheTeamImage = get(this, 'props.data.meetTheTeam.childImageSharp.fluid');
    const transportImage = get(this, 'props.data.transport.childImageSharp.fluid');
    const provisions = get(this, 'props.data.provisions.edges');
    const articles = get(this, 'props.data.articles.edges');
    const slides = get(this, 'props.data.slides.edges');
    const {isLoading} = this.state;
    return (
      <Layout>
        <SEO title="Green Chilli"
          fullTitle={true}
          path={path} />
        <WhiteBackground>
          <HomeHero slides={slides} isLoading={isLoading}/>
          <HomeIntroductionBlock introFeatures={introFeatures} orderSteps={orderSteps} registrationOptions={registrationOptions} />
          <HomeImageBlock provisions={provisions} />
          <AboutUsBlock ourWorldFeatures={ourWorldFeatures} 
                        locationImage={locationImage} 
                        meetTheTeamImage={meetTheTeamImage} 
                        transportImage={transportImage} />
          <HomeNewsBlock articles={articles} />
        </WhiteBackground>
      </Layout>
    );
  }
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    slides: allContentfulCarouselSlide(sort: {fields: sortOrder, order: ASC}) {
      edges {
        node {
          id
          title
          content {
            json
          }
          image: image {
            fluid(
              maxWidth: 1920
              quality: 70
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    articles: allContentfulArticle(sort: { fields: createdAt, order: DESC }, limit: 3) {
      edges {
        node {
          id
          slug
          title
          metaDescription
          thumbnail: thumbnail {
            fluid(
              maxWidth: 1920
              quality: 70
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    provisions: allContentfulProvision(sort: {fields: sortOrder, order: ASC}) {
      edges {
        node {
          id
          slug
          title
          metaDescription
          thumbnail: thumbnail {
            fluid(
              maxWidth: 1920
              quality: 70
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    location: file(
      relativePath: { eq: "home/location.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    meetTheTeam: file(
      relativePath: { eq: "home/meet-the-team.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    transport: file(
      relativePath: { eq: "home/transport.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
  `;
