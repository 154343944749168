
import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import HomeRegisterCard from './HomeRegisterCard';


const BeigeContainer = styled(Container)`
    background: #F9F9F9;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    margin-bottom: 45px;
    @media(min-width: 992px) {
        padding: 45px;
        padding-left: 90px;
        padding-right: 90px;
    }
`;

const RowStyled = styled(Row)`
    height: 100%;
    max-width: 1400px;
    margin: auto;
`;

const ColStyled = styled(Col)`
    height: 100%;
`;

const RegisterInterest = (props) => {
    const {registrationOptions} = props;
    return (
        <BeigeContainer>
            <Container>
                <RowStyled>
                    {registrationOptions.map((registrationOption, index) =>
                        <ColStyled lg={6} key={index}><HomeRegisterCard registrationOption={registrationOption}></HomeRegisterCard></ColStyled>
                    )}
                </RowStyled>
            </Container>
    </BeigeContainer>
    )
}

export default RegisterInterest;