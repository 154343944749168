import React from 'react';
import styled from 'styled-components';
import fonts from '../../../styles/fonts';
import {Link} from 'gatsby'
import colors from '../../../styles/colors';

const HomeRegisterCardContainer = styled.div`
    width: 100%;
    background: white;
    padding: 30px;
    box-sizing: border-box;
    margin: auto;
    margin-bottom: 15px;
    margin-top: 15px;
    @media(min-width: 768px) {
        width: 420px;
        padding-left: 45px;
        padding-right: 40px;
        padding-top: 25px;
        padding-bottom: 25px;
        margin-bottom: 0px;
        margin-top: 0px; 
    }
    @media(min-width: 768px) and (max-width: 992px) {
        margin-bottom: 15px;
        margin-top: 15px;
    }
`;

const HomeRegisterCardTitle = styled.div`
    color: #555555;
    ${fonts.CormorantGaramondBold};
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 4px;
    text-transform: uppercase;
`;

const HomeRegisterCardRegion = styled.div`
    color: #555555;
    ${fonts.MontserratBold};
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 3px;
    text-transform: uppercase;
`;

const BorderLineDiv = styled.div`
    height: 4px;
    width: 117px;
    background: #ADE100;
    margin-top: 20px;
    margin-bottom: 25px;
`;

const HomeRegisterCardDescription = styled.div`
    color: #555555;
    ${fonts.MontserratLight};
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.39px;
`;

const HomeRegisterInvite = styled.div`
    color: #555555;
    ${fonts.MontserratMedium};
    font-size: 13px;
    line-height: 27px;
    letter-spacing: 0.39px;
    padding-bottom: 10px;
`;

const RegisterLogInLink = styled(Link)`
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        border: none;
    }  
`;

const RegisterLogInButton = styled.div`
    height: 44px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    border: 1px solid ${colors.green};
    background: white;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color:${colors.grey};
    ${fonts.MontserratMedium};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    @media(min-width: 768px) {
        width: 294px;
    }
    -webkit-tap-highlight-color: transparent;
    transition: 0.5s;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        background: ${colors.green};
        color: ${colors.white};
    }  
`;

const LogInButtonText = styled.div`
    margin-right: -1.8px;
`;



const HomeRegisterCard = (props) => {
    const {registrationOption} = props;
    return (
        <HomeRegisterCardContainer>
            <HomeRegisterCardTitle>Estimate / Order</HomeRegisterCardTitle>
            <HomeRegisterCardRegion>{registrationOption.region}</HomeRegisterCardRegion>
            <BorderLineDiv />
            <HomeRegisterCardDescription>{registrationOption.description}</HomeRegisterCardDescription>
            <HomeRegisterInvite>Please login / register to view our unrivalled product lists.</HomeRegisterInvite>
            <RegisterLogInLink to='/coming-soon'><RegisterLogInButton><LogInButtonText>Login / create account</LogInButtonText></RegisterLogInButton></RegisterLogInLink>
        </HomeRegisterCardContainer>
    )
}

export default HomeRegisterCard;